<template>
  <div class="page2">
    <!-- 主图片 -->
    <div class="main-image">
      <img :src="require('@/assets/page2_main_image.png')" alt="主图片" />
      <!-- 添加到主图片上的标题 -->
      <div 
        class="text-overlay main-title"
        :style="{
          top: mainTitlePosition.top + '%',
          left: mainTitlePosition.left + '%',
          color: mainTitleColor,
          fontSize: mainTitleFontSize + 'px',
          transform: 'translate(-50%, -50%)' // 确保主标题完全居中
        }"
      >
        {{ mainTitle }}
      </div>
    </div>

    <!-- 居中内容容器 -->
    <div class="content-container">
      <!-- 上部图片 -->
      <div class="upper-image">
        <img :src="require('@/assets/page2_upper_image_1.png')" alt="顶部图片1" />
      </div>

      <!-- 下部图片1 -->
      <div class="lower-image-with-text lower-image-1">
        <img :src="require('@/assets/page2_lower_image_1.png')" alt="底部图片1" />
        <div 
          class="text-overlay left-aligned"
          :style="lowerImage1TitleStyle"
        >
          <h2>{{ lowerImage1Title }}</h2>
        </div>
        <div 
          class="text-overlay left-aligned"
          :style="lowerImage1ContentStyle"
        >
          <p>{{ lowerImage1Content }}</p>
        </div>
      </div>

      <!-- 上部图片2 -->
      <div class="upper-image upper-image-2">
        <img :src="require('@/assets/page2_upper_image_2.png')" alt="顶部图片2" />
      </div>

      <!-- 下部图片2_1 -->
      <div class="lower-image fixed-size lower-image-2-1">
        <img :src="require('@/assets/page2_lower_image_2_1.png')" alt="底部图片2_1" />
      </div>

      <!-- 文本标题 -->
      <h2 class="section-title">{{ sectionTitle2 }}</h2>

      <!-- 文本内容 -->
      <p class="section-content">{{ sectionContent2 }}</p>

      <!-- 下部图片2_2 -->
      <div class="lower-image fixed-size lower-image-2-2">
        <img :src="require('@/assets/page2_lower_image_2_2.png')" alt="底部图片2_2" />
      </div>

      <!-- 文本标题 -->
      <h2 class="section-title">{{ sectionTitle3 }}</h2>

      <!-- 文本内容 -->
      <p class="section-content">{{ sectionContent3 }}</p>

      <!-- 上部图片3 -->
      <div class="upper-image upper-image-3">
        <img :src="require('@/assets/page2_upper_image_3.png')" alt="顶部图片3" />
      </div>

      <!-- 下部图片3 -->
      <div class="lower-image lower-image-3">
        <img :src="require('@/assets/page2_lower_image_3.png')" alt="底部图片3" />
      </div>

      <!-- 上部图片4 -->
      <div class="upper-image upper-image-4">
        <img :src="require('@/assets/page2_upper_image_4.png')" alt="顶部图片4" />
      </div>

      <!-- 图片文本组 -->
      <div class="image-text-grid">
        <div v-for="(item, index) in imageTextGroups" :key="index" class="image-text-pair">
          <div class="image-container">
            <img 
              :src="require(`@/assets/page2_lower_image_4_${index + 1}.png`)" 
              :alt="`图片文本组${index + 1}`"
              class="group-image"
            />
          </div>
          <div class="text-container">
            <h3>{{ item.title }}</h3>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTwo',
  data() {
    return {
      // 主图片标题和样式设置
      mainTitle: '',
      mainTitlePosition: { top: 40, left: 50 }, // 主图片标题位置（百分比）
      mainTitleColor: '#FFFFFF', // 主图片标题颜色
      mainTitleFontSize: 45, // 主图片标题大小（像素）

      // 下部图片1标题和内容位置及样式设置
      lowerImage1Title: '',
      lowerImage1Content: '',
      lowerImage1TextColor: '#CFAD70', // 标题和内容颜色

      // 新增：更具体的文本位置和大小控制
      lowerImage1TitlePosition: { top: 10, left: 5 }, // 标题位置（百分比）
      lowerImage1TitleFontSize: 25, // 标题大小（像素）
      lowerImage1ContentPosition: { top: 35, left: 5 }, // 内容位置（百分比）
      lowerImage1ContentFontSize: 15, // 内容大小（像素）

      // 各部分文本标题和内容
      sectionTitle2: '探索解决文化遗产数字化保护利用问题的新模式',
      sectionContent2: '链接更多文物保护实际应用场景和科学技术问题，探索解决文化遗产数字化保护利用问题的共创模式，\n解决文物保护和利用中的问题。',
      sectionTitle3: '探索文化和科技交叉人才培养新模式',
      sectionContent3: '以问题驱动孕育交叉创新，以大模型、人工智能为引领，培养一批具备更强的解决问题、分析问题，\n能够解决多学科交叉问题的专业人才。',

      // 图片文本组数据
      imageTextGroups: [
        {
          title: '彭群生',
          content: '浙江大学教授\n浙江大学CAD&CG国家重点实验室教授\n致力于图形学交叉学科的建设'
        },
        {
          title: '于振明',
          content: '北京邮电大学副教授\n致力于高速光通信系统、\n计算成像与深度学习、智能通信研究'
        },
        {
          title: '祁庆国',
          content: '北京博物馆学会副理事长\n主要从事博物馆学、博物馆信息学、\n文物影像学等领域研究'
        },
        {
          title: '马严',
          content: '北京邮电大学教授\n致力于网络管理、网络安全技术、\n移动IP、IPv6等技术及其应用'
        },
        {
          title: '周月',
          content: '北京邮电大学副教授\n致力于文化大数据标准体系、\n原真数字化采集、智能图像处理等领域'
        },
        {
          title: '侯小刚',
          content: '博士后\n研究方向为图像智能处理及多模态AI\n在文化遗产数据价值挖掘和解码利用'
        },
        {
          title: '谢伟强',
          content: '领域专家\n致力于情报数据分析及\n人工智能工程领域的深度实践与创新应用'
        },
        {
          title: '熊华',
          content: '领域专家\n主要从事智能图像技术研究及\n在教育、文化、工业等领域的应用'
        }
      ]
    };
  },
  computed: {
    lowerImage1TitleStyle() {
      return {
        top: this.lowerImage1TitlePosition.top + '%',
        left: this.lowerImage1TitlePosition.left + '%',
        color: this.lowerImage1TextColor,
        fontSize: this.lowerImage1TitleFontSize + 'px',
      };
    },
    lowerImage1ContentStyle() {
      return {
        top: this.lowerImage1ContentPosition.top + '%',
        left: this.lowerImage1ContentPosition.left + '%',
        color: this.lowerImage1TextColor,
        fontSize: this.lowerImage1ContentFontSize + 'px',
        maxWidth: '50%', // 设置最大宽度为50%
        whiteSpace: 'normal', // 允许文本换行
      };
    }
  }
};
</script>

<style scoped>
.page2 {
  font-family: 'FZCuJinLJW', sans-serif;
}

.main-image {
  position: relative;
  z-index: 0; /* 确保主图片位于底层 */
}

.main-image img {
  width: 100%;
  height: auto;
}

/* 主图片上的标题样式 */
.text-overlay.main-title {
  position: absolute;
  text-align: center;
  font-weight: bold;
  white-space: nowrap; /* 保持标题不换行 */
}

.content-container {
  width: 80%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px; /* 添加顶部外边距 */
}

.upper-image img {
  width: 100%;
  height: auto;
  margin-bottom: 50px; /* 添加底部外边距 */
}

.upper-image.upper-image-2,
.upper-image.upper-image-3,
.upper-image.upper-image-4 {
  margin-top: 50px; /* 添加顶部外边距 */
}

.lower-image.fixed-size img {
  width: 100px;
  height: 120px;
  display: block;
  margin: 0 auto;
}

.lower-image img {
  width: 100%;
  height: auto;
}

.lower-image-with-text.lower-image-1 {
  position: relative;
  display: flex;
  align-items: flex-start; /* 对齐到顶部 */
  justify-content: flex-start; /* 对齐到左侧 */
  overflow: hidden;
  z-index: 1; /* 确保下部图片及其文本位于主图片之上 */
}

.lower-image-with-text img {
  width: auto;
  height: auto;
  max-width: 100%; /* 防止图片超出容器 */
  max-height: 100vh; /* 或者其他适合你设计的最大高度 */
  display: block;
}

.text-overlay.left-aligned {
  position: absolute;
  text-align: left; /* 确保文本左对齐 */
  padding-left: 10px; /* 添加一些左边距以避免紧贴容器边缘 */
  z-index: 2; /* 确保它位于图片之上 */
  padding: 10px; /* 可选：添加一些内边距 */
  box-sizing: border-box;
}

.section-title {
  font-size: 24px;
  color: #666666;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.section-content {
  font-size: 20px;
  color: #666666;
  margin-bottom: 20px;
  text-align: center;
  white-space:pre-line;
}

.image-text-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.image-text-pair {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.text-container h3 {
  font-size: 20px;
  color: #3D3D3D;
  margin-top: 10px;
  text-align: center;
}

.text-container p {
  font-size: 15px;
  color: #3D3D3D;
  text-align: center;
  white-space:pre-line;
}

</style>