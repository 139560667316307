<template>
    <div class="page4">
      <!-- 主图片 -->
      <div class="main-image">
        <img :src="require('@/assets/page4_main_image.png')" alt="主图片" />
        <div 
          class="text-overlay title"
          :style="{
            top: mainTitlePosition.top + '%',
            left: mainTitlePosition.left + '%',
            color: mainTitleColor,
            fontSize: mainTitleFontSize + 'px',
            transform: 'translate(-50%, -50%)' // 确保主标题完全居中
          }"
        >
          {{ mainTitle }}
        </div>
      </div>
  
      <!-- 顶部图片 -->
      <div class="upper-image">
        <img :src="require('@/assets/page4_upper_image.png')" alt="顶部图片" />
      </div>
  
      <!-- 段落文本 -->
      <div class="text-section">
        <p>{{ mainText }}</p>
      </div>
  
      <!-- 三行二列的图片组 -->
      <div class="image-grid">
        <div v-for="(pair, index) in imagePairs" :key="index" class="image-pair">
          <img 
            :src="require(`@/assets/page4_lower_image_${index + 1}.png`)" 
            :alt="`PNG图片${index + 1}`"
            class="png-image"
          />
          <img 
            :src="require(`@/assets/page4_lower_gif_${index + 1}.gif`)" 
            :alt="`GIF动图${index + 1}`"
            class="gif-image"
          />
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'PageFour',
    data() {
      return {
        mainTitle: '',
        mainTitlePosition: { top: 40, left: 50 }, // 主图片标题位置（百分比）
        mainTitleColor: '#FFFFFF', // 主图片标题颜色
        mainTitleFontSize: 45, // 主图片标题大小（像素）
  
        mainText: '文化资源大数据采存算信传呈工具集，是北京邮电大学“移动媒体与文化计算北京市重点实验室”自主打造的中华传统文化资源数据处理工具平台，旨在应用实验室多年积累的文化计算相关算法，打造一系列生产工具，为文化资源数字化内容实现可量化、可计算、可分析、可展示提供技术支撑，为中华传统文化遗产数字化保护与利用、传统文化内涵揭示及传承机理分析奠定技术基础。',
        
        imagePairs: [
          {}, {}, {}, {}, {}, {} // 六个图片对
        ]
      };
    }
  };
  </script>
<style scoped>
/* 引入字体 */
@import url('@/assets/fonts.css');

.page4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; /* 移除内边距 */
  font-family: 'FZCuJinLJW', sans-serif; /* 设置默认字体 */
}

.main-image img {
  width: 100%; /* 主图片宽度保持不变 */
  height: auto;
  margin: 0 auto; /* 确保图片居中显示 */
}

.text-overlay {
  position: absolute;
  text-align: left;
  font-family: 'FZCuJinLJW', sans-serif; /* 明确设置字体 */
}

.title {
  font-weight: bold;
  white-space: nowrap; /* 保持标题不换行 */
}

.upper-image {
  width: 80%; /* 顶部图片容器宽度 */
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; /* 居中顶部图片容器 */
}

.upper-image img {
  width: 100%; /* 顶部图片宽度占满其容器 */
  height: auto;
  margin: 20px 0; /* 上下间距 */
}

.text-section {
  width: 80%; /* 文本部分宽度 */
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; /* 居中文本容器 */
}

.text-section p {
  font-size: 20px;
  color: #666666; /* 根据需要调整文本颜色 */
  margin: 20px 0;
  text-align: left; /* 文本左对齐 */
  line-height: 1.6; /* 设置行高以保证良好的阅读体验 */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 两列布局 */
  gap: 20px; /* 列之间的间距 */
  margin-top: 20px;
  width: 80%; /* 图片组宽度 */
  max-width: 80%;
  margin-left: auto;
  margin-right: auto; /* 居中图片组 */
}

.png-image{
  width: 100%; /* 占满整个单元格 */
  height: 150px; /* 自动调整高度以保持宽高比 */
  object-fit: contain; /* 确保图像按比例缩放并完全可见 */
  border-radius: 4px; /* 可选：给图片添加圆角 */
}

.gif-image {
  width: 100%; /* 占满整个单元格 */
  height: 300px; /* 自动调整高度以保持宽高比 */
  object-fit: contain; /* 确保图像按比例缩放并完全可见 */
  border-radius: 4px; /* 可选：给图片添加圆角 */
}
</style>