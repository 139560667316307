<template>
    <div class="page5">
      <!-- 主图片 -->
      <div class="main-image">
        <img :src="require('@/assets/page5_main_image.png')" alt="主图片" />
        <div 
          class="text-overlay title"
          :style="{
            top: mainTitlePosition.top + '%',
            left: mainTitlePosition.left + '%',
            color: mainTitleColor,
            fontSize: mainTitleFontSize + 'px',
            transform: 'translate(-50%, -50%)' // 确保主标题完全居中
          }"
        >
          {{ mainTitle }}
        </div>
      </div>
  
      <!-- 上方图片 -->
      <div class="upper-image">
        <img :src="require('@/assets/page5_upper_image.png')" alt="上方图片" />
      </div>
  
      <!-- 段落文本 -->
      <div class="text-section">
        <p>{{ mainText }}</p>
      </div>
  
      <!-- 下方图片组 -->
      <div class="lower-images">
        <div v-for="(item, index) in lowerItems" :key="index" class="lower-item">
          <div class="image-container">
            <img :src="require(`@/assets/${item.image}`)" :alt="item.alt" />
            <div 
              class="text-overlay title"
              :style="{
                top: item.titlePosition.top + '%',
                left: item.titlePosition.left + '%',
                color: item.titleColor,
                fontSize: item.titleFontSize + 'px'
              }"
            >
              {{ item.title }}
            </div>
            <div 
              class="text-overlay content"
              :style="{
                top: item.contentPosition.top + '%',
                left: item.contentPosition.left + '%',
                color: item.contentColor,
                fontSize: item.contentFontSize + 'px',
                maxWidth: item.maxWidth + '%' // 动态设置最大宽度
              }"
            >
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PageFive',
    data() {
      return {
        mainTitle: '',
        mainTitlePosition: { top: 40, left: 50 }, // 主图片标题位置（百分比）
        mainTitleColor: '#FFFFFF', // 主图片标题颜色
        mainTitleFontSize: 45, // 主图片标题大小（像素）
  
        mainText: '文化计算遇到人工智能，必将产生交融与火花，让文化与科技的跨界联名更加透彻，智能化技术让更多文化符号、文化基因、文化精髓挖掘出来、传承出去，展现出奇光异彩！',
  
        lowerItems: [
          {
            image: 'page5_lower_image_1.png',
            alt: '下方图片1',
            title: '文化计算入门',
            titlePosition: { top: 20, left: 27 },
            titleColor: '#29140D',
            titleFontSize: 40,
  
            content: '文化计算 (Cultural Computing) 利用最优化理论、社会计算、大数据、人工智能等技术并与人文、历史等学科相互交叉融合，实现对文化内容挖掘传播、推动数字人文研究，促进文化传承和创新的技术手段。\n\n主讲人：赵海英',
            contentPosition: { top: 40, left: 27 },
            contentColor: '#3D3D3D',
            contentFontSize: 20,
            maxWidth: 65
          },
          {
            image: 'page5_lower_image_2.png',
            alt: '下方图片2',
            title: '传统文化通识课入门',
            titlePosition: { top: 20, left: 27 },
            titleColor: '#29140D',
            titleFontSize: 40,
  
            content: '传统文化通识课入门，是一门引领你探索华夏五千年文明精髓的课程，从诗词歌赋到书法绘画，从古代哲学到民俗风情，深度解读传统文化精髓，培养文化自觉与自信，让智慧与美学点亮你的世界。\n\n主讲人：熊华',
            contentPosition: { top: 40, left: 27 },
            contentColor: '#3D3D3D',
            contentFontSize: 20,
            maxWidth: 65
          },
          {
            image: 'page5_lower_image_3.png',
            alt: '下方图片3',
            title: '文化之旅',
            titlePosition: { top: 20, left: 27 },
            titleColor: '#29140D',
            titleFontSize: 40,
  
            content: '文化计算遇见人工智能，是代表着科技实力的人工智能与代表着中华民族文化基因的文化计算完美融合，也让“跨界”这一词有了更大胆的定义，更明确的目标方向。\n\n主讲人：侯小刚',
            contentPosition: { top: 40, left: 27 },
            contentColor: '#3D3D3D',
            contentFontSize: 20,
            maxWidth: 65
          },
          {
            image: 'page5_lower_image_4.png',
            alt: '下方图片4',
            title: '文化记忆',
            titlePosition: { top: 20, left: 27 },
            titleColor: '#29140D',
            titleFontSize: 40,
  
            content: '人工智能&文化计算印证中华文化源远流长博大精深，推动人工智能的发展、更具智慧！开展文物纹样、色彩、技法、审美、音乐、舞蹈等历史文化价值挖掘研究，揭示传统文化基因的演化规律，用数字技术讲好中国故事。\n\n主讲人：祁庆国',
            contentPosition: { top: 40, left: 27 },
            contentColor: '#3D3D3D',    
            contentFontSize: 20,
            maxWidth: 65
          }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  /* 引入字体 */
  @import url('@/assets/fonts.css');
  
  .page5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0; /* 移除内边距 */
    font-family: 'FZCuJinLJW', sans-serif; /* 设置默认字体 */
  }
  
  .main-image img {
    width: 100%; /* 主图片宽度保持不变 */
    height: auto;
    margin: 0 auto; /* 确保图片居中显示 */
  }
  
  .upper-image {
    text-align: center; /* 使用文本对齐属性使子元素居中 */
  }
  
  .upper-image img {
    max-width: 80%; /* 上方图片宽度 */
    height: auto;
    margin: 20px auto; /* 上下间距 */
  }
  
  .text-section {
    width: 80%; /* 文本部分宽度 */
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; /* 居中文本容器 */
  }
  
  .text-section p {
    font-size: 20px;
    color: #666666; /* 根据需要调整文本颜色 */
    margin: 20px 0;
    text-align: left; /* 文本左对齐 */
    line-height: 1.6; /* 设置行高以保证良好的阅读体验 */
  }
  
  .lower-images {
    width: 80%; /* 图片组宽度 */
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; /* 居中图片组 */
  }
  
  .lower-item {
    position: relative;
    width: 100%; /* 修改宽度为100%，使得每个图片项占据整行 */
    margin-bottom: 20px;
  }
  
  .lower-item .image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .text-overlay {
    position: absolute;
    text-align: left;
    font-family: 'FZCuJinLJW', sans-serif; /* 明确设置字体 */
  }
  
  .title {
    font-weight: bold;
    white-space: nowrap; /* 保持标题不换行 */
  }
  
  .content {
    font-weight: normal;
    white-space: pre-line; /* 允许换行符 \n 转换为换行 */
    word-break: break-word; /* 在必要时断开长单词 */
  }
  </style>