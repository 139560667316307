<!-- src/components/Footer.vue -->
<template>
  <footer class="footer">
    <el-container>
      <el-footer class="custom-footer">
        <div class="footer-content">
          <div class="footer-section contact-us">
            <h3>联系我们</h3>
            <p>地     址：北京邮电大学</p>
            <p>邮     箱：1677319677@qq.com</p>
            <p>邮     编：100876</p>
          </div>
          <div class="footer-section about-us">
            <h3>关注我们</h3>
            <div class="about-us-content">
              <img :src="qrCodeSrc" alt="二维码" class="qr-code" />
              <div class="about-us-text">
                <p>本实验室立足文化资源数据的积淀和价值挖掘，积极融入文化和科技融合国家战略，开展文化计算大脑，文化基因语义模型研究，以守正创新增强中华文明传播力、影响力。</p>
              </div>
            </div>
          </div>
          <div class="footer-section related-platforms">
            <h3>相关平台</h3>
            <p>文化计算在线</p>
            <p>彣芯数据库</p>
            <p>彣脑大模型</p>
          </div>
        </div>
      </el-footer>
    </el-container>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {
      qrCodeSrc: require('@/assets/qrcode.png')  // 确保路径正确
    };
  }
};
</script>

<style scoped>
.footer {
  background-color: #29140D; /* 设置背景颜色 */
  color: #FFFFFF; /* 设置文字颜色为白色 */
}

.custom-footer {
  min-width: 100%; /* 确保 el-footer 占据整个宽度 */
  display: flex;
  justify-content: center; /* 水平居中对齐 */
  padding: 20px 0; /* 可以根据需要调整内边距 */
  min-height: 300px; /* 设置最小高度，确保内容完全显示 */
}

.footer-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; /* 两端对齐，中间有均匀间隔 */
  width: 100%;
  max-width: 1200px; /* 可以根据需要调整最大宽度 */
  padding: 0 40px; /* 增加内边距 */
}

.contact-us, .related-platforms {
  flex: 1; /* 使“联系我们”和“相关平台”占据较少的空间 */
  margin: 0 20px; /* 增加间距 */
}

.about-us {
  flex: 3; /* 使“关注我们”占据更多的空间 */
  margin: 0 20px; /* 增加间距 */
}

.footer-section h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #CFAD70; /* 标题颜色与 Header 一致 */
}

.footer-section p {
  font-size: 1rem;
  line-height: 1.8; /* 增加行高，使文字更易读 */
  margin: 0;
  color: #FFFFFF; /* 文字颜色为白色 */
}

/* 添加一些额外的样式 */
.footer {
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2); /* 底部阴影 */
}

.footer-content {
  border-top: 1px solid rgba(255, 255, 255, 0.1); /* 顶部边框 */
  padding-top: 20px; /* 顶部内边距 */
}

.footer-section:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.1); /* 分隔线 */
  padding-right: 20px; /* 右侧内边距 */
}

.about-us-content {
  display: flex;
  align-items: flex-start;
  margin-top: 10px; /* 二维码容器与上方标题之间的间距 */
}

.qr-code {
  width: 80px; /* 二维码图片的宽度 */
  height: 80px; /* 二维码图片的高度 */
  margin-right: 20px; /* 二维码图片与文字之间的间距 */
}

.about-us-text {
  flex: 1; /* 使文字部分占据剩余空间 */
}
</style>