<template>
  <header class="header">
    <el-container>
      <el-header class="custom-header">
        <div class="header-content">
          <div class="logo-container">
            <img :src="logoSrc" alt="网站icon" class="logo" />
            <span class="site-name">文化计算大脑</span>
          </div>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            background-color="#29140D"
            text-color="#CFAD70"
            active-text-color="#CFAD70"
          >
            <el-menu-item
              v-for="(item, index) in menuItems"
              :key="index"
              :index="item.index"
              :to="item.to"
              :class="{'is-active': activeIndex === item.index}"
            ><span>{{ item.label }}</span></el-menu-item>
          </el-menu>
        </div>
      </el-header>
    </el-container>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
  data() {
    return {
      activeIndex: this.$route.path === '/' ? '1' : this.$route.path.replace('/page', ''),
      logoSrc: require('@/assets/logo.png'),
      menuItems: [
        { index: '1', label: '文化计算大脑', to: '/page1' },
        { index: '2', label: '彣芯雕龙计划', to: '/page2' },
        { index: '3', label: '文化记忆符号', to: '/page3' },
        { index: '4', label: '文化计算在线', to: '/page4' },
        { index: '5', label: '文化计算课程', to: '/page5' },
        { index: '6', label: '活化传播应用', to: '/page6' }
      ]
    };
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
      this.$router.push({ path: `/page${key}` });
    }
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path === '/' ? '1' : to.path.replace('/page', '');
    }
  }
};
</script>

<style scoped>
.header {
  background-color: #29140D;
  color: #CFAD70;
}

.custom-header {
  min-width: 100%;
  display: flex;
  justify-content: center;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-right: 100px;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.site-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.el-menu {
  border-bottom: none;
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  white-space: nowrap;
  overflow: visible;
}

.el-menu-item {
  color: #CFAD70 !important;
  font-size: 1rem;
  padding: 10px 20px;
  transition: all 0.3s ease;
  position: relative;
}

/* 悬浮状态下的样式 */
.el-menu-item:hover {
  background: linear-gradient(45deg, #CFAD70, #8B6E45); /* 渐变背景 */
  color: #fff !important; /* 确保悬浮时字体颜色为白色 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* 添加浮动效果 */
}

/* 激活状态下的样式 */
.el-menu-item.is-active {
  background: transparent; /* 移除激活状态的背景色 */
  box-shadow: none; /* 移除激活状态的阴影 */
  transform: none; /* 移除激活状态的浮动效果 */
  color: #CFAD70 !important; /* 恢复默认字体颜色 */
}

/* 文字下方的下划线样式 */
.el-menu-item span::after {
  content: '';
  position: absolute;
  bottom: 5px; /* 调整下划线距离文字底部的位置 */
  left: 0;
  right: 0;
  width: 100%; /* 下划线宽度与文字宽度一致 */
  height: 2px;
  background-color: transparent; /* 默认透明 */
  transition: all 0.3s ease;
}

/* 激活状态下显示下划线 */
.el-menu-item.is-active span::after {
  background-color: #1890ff; /* 显示蓝色下划线 */
}
</style>