<template>
    <div class="page6">
      <!-- 主图片 -->
      <div class="main-image">
        <img :src="require('@/assets/page6_main_image.png')" alt="主图片" />
        <div 
          class="text-overlay title"
          :style="{
            top: mainTitlePosition.top + '%',
            left: mainTitlePosition.left + '%',
            color: mainTitleColor,
            fontSize: mainTitleFontSize + 'px',
            transform: 'translate(-50%, -50%)' // 确保主标题完全居中
          }"
        >
          {{ mainTitle }}
        </div>
      </div>
  
      <!-- 顶部图片 -->
      <div class="upper-image">
        <img :src="require('@/assets/page6_upper_image.png')" alt="顶部图片" />
      </div>
  
      <!-- 段落文本 -->
      <div class="text-section">
        <p>{{ mainText }}</p>
      </div>
  
      <!-- 二行二列的文本加PNG图片组合 -->
      <div class="image-text-grid">
        <div v-for="(item, index) in imageTextPairs" :key="index" class="image-text-pair">
          <div class="text-container">
            <p>{{ item.text }}</p>
          </div>
          <div class="image-container">
            <img 
              :src="require(`@/assets/page6_lower_image_${index + 1}.png`)" 
              :alt="`PNG图片${index + 1}`"
              class="png-image"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PageSix',
    data() {
      return {
        mainTitle: '',
        mainTitlePosition: { top: 40, left: 50 }, // 主图片标题位置（百分比）
        mainTitleColor: '#FFFFFF', // 主图片标题颜色
        mainTitleFontSize: 45, // 主图片标题大小（像素）
  
        mainText: '中华传统文化的“活化利用”旨在通过创新方式让传统文化焕发新生。2023年，推出了中华文明云展，让观众足不出户领略文物风采，2024年，打造了“中华纹样五千年”沉浸展，传承精美纹样文化，举办了泰国展、中阿展和欧洲巡展，促进文化交流互鉴，让中国传统文化更加鲜活、生动，增强了文化自信。',
  
        imageTextPairs: [
          {
            text: '看见中国——国际数字文化展',
            image: 'page6_lower_image_1.png'
          },
          {
            text: '中华文明云展',
            image: 'page6_lower_image_2.png'
          },
          {
            text: '中华纹样五千年',
            image: 'page6_lower_image_3.png'
          },
          {
            text: '齐鲁文化基因解码',
            image: 'page6_lower_image_4.png'
          }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  /* 引入字体 */
  @import url('@/assets/fonts.css');
  
  .page6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0; /* 移除内边距 */
    font-family: 'FZCuJinLJW', sans-serif; /* 设置默认字体 */
  }
  
  .main-image img {
    width: 100%; /* 主图片宽度保持不变 */
    height: auto;
    margin: 0 auto; /* 确保图片居中显示 */
  }
  
  .text-overlay {
    position: absolute;
    text-align: left;
    font-family: 'FZCuJinLJW', sans-serif; /* 明确设置字体 */
  }
  
  .title {
    font-weight: bold;
    white-space: nowrap; /* 保持标题不换行 */
  }
  
  .upper-image {
    width: 80%; /* 顶部图片容器宽度 */
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; /* 居中顶部图片容器 */
  }
  
  .upper-image img {
    width: 100%; /* 顶部图片宽度占满其容器 */
    height: auto;
    margin: 20px 0; /* 上下间距 */
  }
  
  .text-section {
    width: 80%; /* 文本部分宽度 */
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; /* 居中文本容器 */
  }
  
  .text-section p {
    font-size: 20px;
    color: #666666; /* 根据需要调整文本颜色 */
    margin: 20px 0;
    text-align: left; /* 文本左对齐 */
    line-height: 1.6; /* 设置行高以保证良好的阅读体验 */
  }
  
  .image-text-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 两列布局 */
    grid-template-rows: repeat(2, 1fr); /* 两行布局 */
    gap: 20px; /* 单元格之间的间距 */
    margin-top: 20px;
    width: 80%; /* 图片组宽度 */
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; /* 居中图片组 */
  }
  
  .image-text-pair {
    display: flex;
    flex-direction: column; /* 文本在上，图片在下 */
    align-items: center; /* 居中对齐 */
  }
  
  .text-container p {
    font-size: 20px;
    color: #666666; /* 根据需要调整文本颜色 */
    margin-bottom: 10px;
    text-align: center; /* 文本居中 */
    line-height: 1.6; /* 设置行高以保证良好的阅读体验 */
  }
  
  .png-image {
    width: 100%; /* 占满整个单元格 */
    height: auto; /* 自动调整高度以保持宽高比 */
    object-fit: contain; /* 确保图像按比例缩放并完全可见 */
    border-radius: 4px; /* 可选：给图片添加圆角 */
  }
  </style>