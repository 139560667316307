<template>
    <div class="page1">
      <!-- 主图片 -->
      <div class="main-image">
        <img :src="require('@/assets/page1_main_image.png')" alt="主图片" />
        <div 
          class="text-overlay title"
          :style="{
            top: mainTitlePosition.top + '%',
            left: mainTitlePosition.left + '%',
            color: mainTitleColor,
            fontSize: mainTitleFontSize + 'px',
            transform: 'translate(-50%, -50%)' // 确保主标题完全居中
          }"
        >
          {{ mainTitle }}
        </div>
      </div>
  
      <!-- 重复的样式区域 -->
      <div class="repeated-sections">
        <div v-for="(item, index) in items" :key="index" class="section">
          <div class="upper-image">
            <img :src="require(`@/assets/page1_upper_image_${index + 1}.png`)" alt="上方图片" />
          </div>
          <div class="lower-image">
            <img :src="require(`@/assets/page1_lower_image_${index + 1}.png`)" alt="下方图片" />
            <div 
              class="text-overlay title"
              :style="{
                top: item.titlePosition.top + '%',
                left: item.titlePosition.left + '%',
                color: item.titleColor,
                fontSize: item.titleFontSize + 'px'
              }"
            >
              {{ item.title }}
            </div>
            <div 
              class="text-overlay content"
              :style="{
                top: item.contentPosition.top + '%',
                left: item.contentPosition.left + '%',
                color: item.contentColor,
                fontSize: item.contentFontSize + 'px',
                maxWidth: item.maxWidth + '%' // 动态设置最大宽度
              }"
            >
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
export default {
  name: 'PageOne',
  data() {
    return {
      mainTitle: '',
      mainTitlePosition: { top: 40, left: 50 }, // 主图片标题位置（百分比）
      mainTitleColor: '#FFFFFF', // 主图片标题颜色
      mainTitleFontSize: 45, // 主图片标题大小（像素）

      items: [
        {
          title: '',
          titlePosition: { top: 30, left: 15 }, // 调整为垂直居中偏上一点，水平偏左
          titleColor: '#CFAD70', // 标题颜色
          titleFontSize: 40, // 标题大小（像素）

          content: '',
          contentPosition: { top: 50, left: 15 }, // 内容紧跟标题下方，同样偏左
          contentColor: '#CFAD70', // 内容颜色
          contentFontSize: 15, // 内容大小（像素）
          maxWidth: 45 // 设置最大宽度为45%
        },
        // 确保有5个items对象以对应5张上方和下方图片
        {
          title: '中华传统文化记忆符号库',
          titlePosition: { top: 35, left: 35 },
          titleColor: '#FFFFFF',
          titleFontSize: 40,

          content: '中华传统文化记忆符号库是一个综合性的文化资源库，它涵盖了纹样库、颜色库、工法库和型制库等多个方面。',
          contentPosition: { top: 55, left: 25 },
          contentColor: '#FFFFFF',
          contentFontSize: 15,
          maxWidth: 60 // 设置最大宽度为60%
        },
        {
          title: '文化资源大数据·采存算信传呈',
          titlePosition: { top: 30, left: 45 },
          titleColor: '#000000',
          titleFontSize: 40,

          content: '旨在应用实验室多年积累的文化计算相关算法，打造一系列生产工具，为文化资源数字化内容实现可量化、可计算、可分析、可展示提供技术支撑，为中华传统文化遗产数字化保护与利用、传统文化内涵揭示及传承机理分析奠定技术基础。',
          contentPosition: { top: 50, left: 45 },
          contentColor: '#000000',
          contentFontSize: 15,
          maxWidth: 40 // 设置最大宽度为50%
        },
        {
          title: '文化计算课程体系',
          titlePosition: { top: 30, left: 60 },
          titleColor: '#AE4032',
          titleFontSize: 40,

          content: '文化计算遇到人工智能，让文化与科技的跨界联名更加透彻，智能化技术让更多文化符号、文化基因、文化精髓挖掘出来、传承出去，展现出奇光异彩。',
          contentPosition: { top: 50, left: 60 },
          contentColor: '#AE4032',
          contentFontSize: 15,
          maxWidth: 25 // 设置最大宽度为55%
        },
        {
          title: '文化计算活化应用',
          titlePosition: { top: 30, left: 15 },
          titleColor: '#CFAD70',
          titleFontSize: 40,

          content: '中华传统文化的“活化利用”旨在通过创新方式让传统文化焕发新生。',
          contentPosition: { top: 50, left: 15 },
          contentColor: '#CFAD70',
          contentFontSize: 15,
          maxWidth: 25 // 设置最大宽度为40%
        }
      ]
    };
  }
};
</script>
  
<style scoped>
/* 引入字体 */
@import url('@/assets/fonts.css');

.page1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; /* 移除内边距 */
  font-family: 'FZCuJinLJW', sans-serif; /* 设置默认字体 */
}

.main-image img {
  width: 100%; /* 主图片宽度保持不变 */
  height: auto;
  margin: 0 auto; /* 确保图片居中显示 */
}

.upper-image,
.lower-image {
  position: relative;
  width: 100%; /* 确保父元素占满整个宽度 */
  text-align: center; /* 父元素内部文本和行内元素居中 */
}

.upper-image img,
.lower-image img {
  display: block; /* 确保img作为块级元素 */
  max-width: 80%; /* 设置最大宽度为80% */
  height: auto;
  margin: 0 auto; /* 确保图片水平居中 */
}

.text-overlay {
  position: absolute;
  text-align: left;
  font-family: 'FZCuJinLJW', sans-serif; /* 明确设置字体 */
}

.title {
  font-weight: bold;
  white-space: nowrap; /* 保持标题不换行 */
}

.content {
  font-weight: normal;
  white-space: normal; /* 允许内容换行 */
  word-break: break-word; /* 在必要时断开长单词 */
}
</style>