<!-- src/App.vue -->
<template>
  <div id="app" class="app-container">
    <Header />
    <main class="main-content">
      <router-view></router-view>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
};
</script>

<style>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 确保容器至少占据整个视口高度 */
}

.main-content {
  flex: 1; /* 主内容区域占据剩余空间 */
  padding: 20px; /* 可以根据需要调整内边距 */
}

/* 其他全局样式 */
</style>