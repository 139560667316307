// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import PageOne from '@/views/Page1.vue';
import PageTwo from '@/views/Page2.vue';
import PageThree from '@/views/Page3.vue';
import PageFour from '@/views/Page4.vue';
import PageFive from '@/views/Page5.vue';
import PageSix from '@/views/Page6.vue';

const routes = [
  { path: '/page1', name: 'PageOne', component: PageOne },
  { path: '/page2', name: 'PageTwo', component: PageTwo },
  { path: '/page3', name: 'PageThree', component: PageThree },
  { path: '/page4', name: 'PageFour', component: PageFour },
  { path: '/page5', name: 'PageFive', component: PageFive },
  { path: '/page6', name: 'PageSix', component: PageSix },
  { path: '/', redirect: '/page1' }, // 默认重定向到 Page1
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;