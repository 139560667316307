<template>
    <div class="page3">
      <!-- 主图片 -->
      <div class="main-image">
        <img :src="require('@/assets/page3_main_image.png')" alt="主图片" />
        <div 
          class="text-overlay title"
          :style="{
            top: mainTitlePosition.top + '%',
            left: mainTitlePosition.left + '%',
            color: mainTitleColor,
            fontSize: mainTitleFontSize + 'px',
            transform: 'translate(-50%, -50%)' // 确保主标题完全居中
          }"
        >
          {{ mainTitle }}
        </div>
      </div>
  
      <!-- 背景图片和页面内容 -->
      <div class="content-wrapper">
        <!-- 顶部图片 -->
        <div class="top-image">
          <img :src="require('@/assets/page3_upper_image.png')" alt="顶部图片" />
        </div>
  
        <!-- 段落文本 -->
        <div class="text-section">
          <p>{{ mainText }}</p>
        </div>
  
        <!-- 最下方4张图片 -->
        <div class="bottom-images">
          <div v-for="(image, index) in bottomImages" :key="index" class="image-container">
            <img 
              :src="require(`@/assets/page3_lower_image_${index + 1}.png`)" 
              :alt="`底部图片${index + 1}`"
              @mouseover="showTooltip = index"
              @mouseleave="showTooltip = null"
            />
            <div v-if="showTooltip === index" class="tooltip">{{ image.tooltip }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'PageThree',
  data() {
    return {
      mainTitle: '',
      mainTitlePosition: { top: 40, left: 50 }, // 主图片标题位置（百分比）
      mainTitleColor: '#FFFFFF', // 主图片标题颜色
      mainTitleFontSize: 45, // 主图片标题大小（像素）

      mainText: '中华传统文化记忆符号库是一个综合性的文化资源库，它涵盖了纹样库、颜色库、工法库和型制库等多个方面。符号库致力于解码中华传统文化中的记忆符号，通过精心搜集和整理古代器物、建筑、服饰等领域的经典元素，追溯并传承华夏文明的独特美学。无论是繁复精美的纹样，还是典雅古朴的颜色搭配，或是精湛细腻的工艺技法，以及端庄大气的型制设计，都将在这个库中得到完美的呈现，为后人提供学习和借鉴的宝贵资源。',
      showTooltip: null,
      bottomImages: [
        {
          tooltip: '汇聚丰富多样的中国传统纹样，传承与展现华夏文明的独特美学。'
        },
        {
          tooltip: '收录并展示中华传统色彩的丰富多样，追溯与传承华夏文明的色彩美学。'
        },
        {
          tooltip: '汇集古代匠人智慧，展现中华传统工艺与技法的精湛与独特。'
        },
        {
          tooltip: '集录中国古代器物与建筑的经典型制，彰显华夏文明的造型艺术与构造智慧。'
        }
      ]
    };
  }
};
</script>

<style scoped>
/* 引入字体 */
@import url('@/assets/fonts.css');

.page3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; /* 移除内边距 */
  font-family: 'FZCuJinLJW', sans-serif; /* 设置默认字体 */
}

.main-image img {
  width: 100%; /* 主图片宽度保持不变 */
  height: auto;
  margin: 0 auto; /* 确保图片居中显示 */
}

.text-overlay {
  position: absolute;
  text-align: left;
  font-family: 'FZCuJinLJW', sans-serif; /* 明确设置字体 */
}

.title {
  font-weight: bold;
  white-space: pre-line; /* 保持标题不换行 */
}

.content-wrapper {
  text-align: center;
  width: 80%;
  max-width: 1200px;
}

.top-image img {
  width: 100%; /* 顶部图片宽度保持不变 */
  height: auto;
  margin: 0 auto; /* 确保图片居中显示 */
}

.text-section p {
  font-size: 20px;
  color: #666666; /* 根据需要调整文本颜色 */
  margin: 20px 0;
  text-align: left; /* 文本左对齐 */
  line-height: 1.6; /* 设置行高以保证良好的阅读体验 */
}

.bottom-images {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.image-container {
  position: relative;
  width: 22%; /* 适应四张图片横向排列 */
  margin: 0 5px;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.tooltip {
  position: absolute;
  bottom: 10px; /* 调整位置，使文字说明不紧贴图片下沿 */
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 10px 24px; /* 增加底部内边距，留出更多空白 */
  border-radius: 4px;
  font-size: 19px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
  max-width: 100%;
  word-wrap: break-word;
}

.image-container:hover .tooltip {
  opacity: 1;
}
</style>